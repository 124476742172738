/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.min.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.academic-title {
  align-items: center;
}

.experience-title {
  align-items: center;
  margin-bottom: 20px;
}

.experience-item-ml {
  margin-right: 10px;
}

.experience-item-text-ml {
  margin-right: 7px;
}

.mb-cards {
  margin-bottom: 10px;
}

.display-flex {
  display: flex;
}

.techstack-div {
  margin-top: -20px;
  margin-bottom: 20px;
}

.width-50-pc {
  width: 50%;
}

.width-60-pc {
  width: 60%;
}

.pl-50-px {
  padding-left: 50px;
}

@media screen and (max-width: 768px) {
  .flexColumn {
      flex-flow: column;
  }
}